html,body{
  overflow-x: hidden;
}
#autoaudioplay{
  height: 0;
  width: 0;
  visibility: hidden;
}
#autoaudioplay1{
  height: 0;
  width: 0;
  visibility: hidden;
}
.guZdik.is-disabled{
  border: 0px !important;
}
body {
  margin: 0;
  padding: 0 !important;
  scroll-behavior: smooth;
}

body.user-panel{min-height: 100dvh;}

h1,h2,h3,h4,h5,h6{margin-top: 0;margin-bottom: 15px;}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: none !important;
}

#webpack-dev-server-client-overlay{display: none !important;}

.text-right{text-align: right;}


.pb-0{padding-bottom: 0 !important;}


.text-truncate,
.text-truncate-2,
.text-truncate-4{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
.text-truncate-2{
  -webkit-line-clamp: 2;
}
.text-truncate-4{
  -webkit-line-clamp: 4;
}





/* CUSTOM SCROLLBAR START */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  border: 1px solid #ddd;
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 3px;
  border: 1px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc;
}
/* CUSTOM SCROLLBAR END */







.main{
  margin: auto;
  padding-bottom: 54px;
}
.user-panel .container{
  padding-left: 20px;
  padding-right: 20px;
}

.error-msg{
  color: red;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  font-family: inherit;
  margin: 2px 0;
}
.cover,.contain{
  width: 100%;
  height: 100%;
  line-height: 0;
  object-position: center;
}
.cover{
  object-fit: cover;
}
.contain{
  object-fit: contain;
}


.form-group{margin-bottom: 27px;}
.form-label{
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  margin-bottom: 9px;
}
.form-input,
.form-input:focus{
  padding: 14px 16px !important;
  box-shadow: none !important;
  outline: 0;
  min-height: 46px !important;
}
.form-input::before{box-shadow: none !important;}

.btn{
  min-height: 48px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  box-shadow: none !important;
  outline: none;
}

.login-card form{
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.input-group{
  position: relative;
}
.input-group .icon{
  padding: 0 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  cursor: pointer;
}
.input-group .icon svg{
  width: 20px;
  height: 16px;
}

.MuiSelect-root .MuiSelect-button{
  display: block;
  text-align: left;
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



ul.base-Popper-root{
  padding: 0px;
  border: 1px solid #F4F6F8;
  z-index: 1400;
}
ul.base-Popper-root li{
  padding: 14px 16px;
  background-color: #fff !important;
  border-bottom: 1px solid #F4F6F8;
}
ul.base-Popper-root li:last-child{
  border-bottom: none;
}




.slick-track{
  display: flex !important;
  margin-left: -8px !important;
  margin-right: -8px !important;
  /* justify-content: center; */
  /* min-width: 100%; */
}
.slick-track .slick-slide{
  padding-left: 8px;
  padding-right: 8px;
  height: auto !important;
  line-height: 0;
}

.slick-dots li{
  width: auto !important;
  height: auto !important;
  margin: auto 3px !important;
}

.slick-dots li button{
  position: relative;
  padding: 0px !important;
  border-radius: 100%;
}
.slick-dots li button:before{
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0 !important;
}
.slick-dots li.slick-active button{
  background: transparent !important;
}
.slick-dots li.slick-active button:before{
  opacity: 1 !important;
}






.check-in-error{
  min-height: calc(100vh - 64px - 25px);
  display: flex;
  flex-direction: column;
}


.rotate svg {
  animation: rotation infinite 1.5s linear;
}

@keyframes rotation{
  from{
    transform:rotate(360deg);
  }
  
  to{
    transform:rotate(0deg);
  }
}


.checkmark {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    display: block;
    stroke-width: 3;
    stroke: #01C448;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #01C448;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #01C448;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #01C448;
    }
}


body.staff-panel .header{
justify-content: flex-start !important;
}



















@media(max-width: 600px){
  .slick-track{
    margin-left: -4px !important;
    margin-right: -4px !important;
  }
  .slick-track .slick-slide{
    padding-left: 4px;
    padding-right: 4px;
  }
}





@media(max-width: 340px){
  .user-panel .container{
    padding-left: 15px;
    padding-right: 15px;
  }
  .slick-track{
    margin-left: -2.5px !important;
    margin-right: -2.5px !important;
  }
  .slick-track .slick-slide{
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
}