.scanner-container{
  position: relative;
  line-height: 0;
  height: 100vh;

  --WindowWidth: 250px;
  --WindowBorderWidth: 4px;
  --ContainerWidth: calc(100% - var(--WindowWidth) - 2*(var(--WindowBorderWidth)));
  --OverlayXWidth: calc(var(--ContainerWidth) / 2);
  --ContainerHeight: calc(100% - var(--WindowWidth) - 2*(var(--WindowBorderWidth)));
  --OverlayYHeight: calc(var(--ContainerHeight) / 2);
  --BorderAngleColor: #fff;
  --OverlayColor: rgba(0,0,0,1);


}
.scanner-container video{
  width: auto;
  height: 100%;
  border: none;
}
.overlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
}
.overlay .text{
  margin-top: auto;
}
.overlay .btn-cancel{
  margin-bottom: auto;
}

.overlay.x:after,
.overlay.x:before,
.overlay.y:after,
.overlay.y:before{
  content: "";
  position: absolute;
  background: var(--OverlayColor);
}

.overlay.x:after,
.overlay.x:before{
  height: 100%;
  width: var(--OverlayXWidth);
}

.overlay.x:after{
  right: 0;
}
.overlay.x:before{
  left: 0;
}


.overlay.y:after,
.overlay.y:before{
  width: calc(var(--WindowWidth) + 2*(var(--WindowBorderWidth)));
  height: var(--OverlayYHeight);
}
.overlay.y:after{
  top: 0;
}
.overlay.y:before{
  bottom: 0;
}

.scanner-window{
  width: var(--WindowWidth);
  height: var(--WindowWidth);
  background: transparent;
  margin: auto auto;
  position: relative;
}
.scanner-window .inner{
  width: 100%;
  height: 100%;
  position: relative;
}

.scanner-window:after,
.scanner-window:before,
.scanner-window .inner:after,
.scanner-window .inner:before{
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: transparent;
  border-width: var(--WindowBorderWidth);
  border-style: solid;
  position: absolute;
}

.scanner-window:after{
  border-top-color: var(--BorderAngleColor);
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: var(--BorderAngleColor);
  top: calc(var(--WindowBorderWidth) * -3);
  left: calc(var(--WindowBorderWidth) * -3);
}
.scanner-window:before{
  border-top-color: var(--BorderAngleColor);
  border-right-color: var(--BorderAngleColor);
  border-bottom-color: transparent;
  border-left-color: transparent;
  right: calc(var(--WindowBorderWidth) * -3);
  top: calc(var(--WindowBorderWidth) * -3);
}
.scanner-window .inner:before{
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: var(--BorderAngleColor);
  border-left-color: var(--BorderAngleColor);
  left: calc(var(--WindowBorderWidth) * -3);
  bottom: calc(var(--WindowBorderWidth) * -3);
}
.scanner-window .inner:after{
  border-top-color: transparent;
  border-right-color: var(--BorderAngleColor);
  border-bottom-color: var(--BorderAngleColor);
  border-left-color: transparent;
  right: calc(var(--WindowBorderWidth) * -3);
  bottom: calc(var(--WindowBorderWidth) * -3);
}

.line{
  width: calc(100% + var(--WindowBorderWidth) + var(--WindowBorderWidth));
  height: var(--WindowBorderWidth);
  background: var(--BorderAngleColor);
  position: absolute;
  left: calc(var(--WindowBorderWidth) * -1);
  right: 0;
  top: 0;
  animation: swing ease-in-out 2s infinite alternate;
}

@keyframes swing {
  0% { transform: translateY(var(--WindowWidth)); }
  100% { transform: translateY(-100%); }
}

@media(max-height: 575px){
  .scanner-container{
    --WindowWidth: 150px;
    --WindowBorderWidth: 2px;
  }
  .overlay{
    padding: 5px 0;
  }

  .scanner-container video{
    width: 100%;
  }
}
@media(max-width: 340px){
  .scanner-container{
    --WindowWidth: 240px;
  }
}