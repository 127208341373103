:root{
 --HeaderHeight: 350px;
 --TabHeaderHeight: 160px;
 --BoxTopMargin: -100px;
 --BoxHeight: calc(100vh - (var(--HeaderHeight) + var(--BoxTopMargin) + 15px));
 --FormContainer: calc(var(--BoxHeight) - var(--TabHeaderHeight) - var(--TabHeaderHeight) );
 --ThemeColor: #3699FF;
}

.mt-auto,.my-auto{margin-top: auto !important;}
.mb-auto,.my-auto{margin-bottom: auto !important;}
.ml-auto,.mx-auto{margin-left: auto !important;}
.mr-auto,.mx-auto{margin-right: auto !important;}
.mt-0,.my-0{margin-top: 0 !important;}
.mb-0,.my-0{margin-bottom: 0 !important;}
.ml-0,.mx-0{margin-left: 0 !important;}
.mr-0,.mx-0{margin-right: 0 !important;}


.admin-panel .MuiPickersPopper-root .MuiClock-pin,
.admin-panel .MuiPickersPopper-root .MuiClockPointer-root,
.admin-panel .MuiPickersPopper-root .MuiClockPointer-thumb,
.admin-panel .MuiPickersLayout-root .MuiPickersDay-root.Mui-selected{
    background-color: var(--ThemeColor) !important;
}
.admin-panel .MuiPickersPopper-root .MuiClockPointer-thumb{
    border-color: var(--ThemeColor) !important;
}
.admin-panel .MuiPickersPopper-root .MuiButton-text,
.admin-panel .MuiPickersLayout-root .MuiButton-text{
    color: var(--ThemeColor) !important;
}

.admin-main{
    min-height: 100vh;
    background-color: #EEF0F8;
    /* padding-bottom: 54px; */
}

.cover{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    line-height: 0;
}
.container,
.participant-slider{
    max-width: 1199px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.hedaer{
    position: relative;
    height: var(--HeaderHeight);
}
.hedaer hr{border-color: rgba(255, 255, 255, 0.13);}
.hedaer .content{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}


.navbar{
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 52px 0px 20px 0px;
}
.nav-left,
.nav-right{
    display: flex;
    gap: 8px;
    align-items: center;
}
.nav-right {gap: 16px;}
.nav-left button{
    height: 36px;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: none;
    padding-left: 12px;
    padding-right: 12px;
}


.admin-breadcrumbs{padding-top: 40px;}
.admin-breadcrumbs h1{
    letter-spacing: 0em;
}
.admin-breadcrumbs-container{
    display: flex;
    align-items: center;
}
.admin-breadcrumbs-container ul{
    padding-left: 24px;
}

.dbbox,.box{
    position: relative;
    margin-top: var(--BoxTopMargin);
    z-index: 3;
}
.box{
    background-color: #fff;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    min-height: var(--BoxHeight);
}

.tabheader{
    position: relative;
}
.tabheader:after,
.tabheader:before{
    /* content: ""; */
    width: 20px;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    backdrop-filter: blur(1.5px);
}
.tabheader:after{
    left: 0;
    background: linear-gradient(to right, rgba(255,255,255,0.35), transparent);
}
.tabheader:before{
    right: 0;
    background: linear-gradient(to left, rgba(255,255,255,0.35), transparent);
}
.tabheader .inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1045px;
    margin-left: auto;
    margin-right: auto;
    height: var(--TabHeaderHeight);
    padding: 0 20px;
    overflow-x: auto;
    white-space: nowrap;
}
.tabheader svg{flex-shrink: 0;}
.tabheader .admin-btn-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100px;
    flex-shrink: 0;
    white-space: normal;
}
.tabheader .admin-btn-box-icon{
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
}
.tabheader .admin-btn-box-icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.form-container{
    max-width: 717px;
    margin-left: auto;
    margin-right: auto;
    padding: 54px 30px;
    display: flex;
    flex-direction: column;
    min-height: var(--FormContainer);
}
.form-container-footer{
    padding-top: 96px;
    margin-top: auto;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ck.ck-powered-by{display: none !important;}




.custom-table{
    width: 100%;
    border-collapse: collapse;
    border: none;
}
.custom-table th,
.custom-table td{
    padding: 10px;
    text-align: center;
    border: none;
}
.custom-table th:first-child,
.custom-table td:first-child{
    text-align: left;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.custom-table th:last-child,
.custom-table td:last-child{
    text-align: right;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.custom-table th{
    background-color: #F3F6F9;
}

.sec-bottom-sapce{
    padding-bottom: 54px;
}

.speaker-container .speaker-dec-card{margin-top: 20px;}
.speaker-info-form{margin-top: 20px;}
.speaker-container.scrollable,
.info-card-container.scrollable{
    border: 1px solid;
    padding: 5px;
    max-height: 460px;
    overflow-y: auto;
    border-color: #F3F6F9;
    padding-bottom: 20px;
    margin-top: 20px !important;
}
.info-card-container.scrollable{
    max-height: 740px;
}

.info-card-container{margin-top: 30px;}


.MuiPickersToolbar-root{
    padding-bottom: 0px !important;
}



@media(max-width: 900px){
    .tabheader .admin-btn-box{
        width: 150px;
    }
    .tabheader .admin-btn-box .admin-btn-box-icon{
        width: 38px;
        height: 38px;
        margin-bottom: 15px;
    }
    .tabheader .admin-btn-box .admin-btn-box-text{
        font-size: 12px;
        line-height: 16px;
    }

}
@media(max-width: 768px){
    .speaker-info-form .rw{
        flex-direction: column;
    }
    .speaker-info-form .img-upload-container{
        margin-bottom: 30px;
        margin-top: -15px;
    }
    .speaker-info-form .img-upload-container .upload-image-card{
        width: 100%;
    }
    .nav-left, .nav-right{gap: 4px;}
    .navbar{padding: 30px 0px 15px 0px;}
}

@media(max-width: 768px) and (min-width: 576px) {
    .nav-left button{
        height: 32px;
        font-size: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

}

@media(max-width: 500px){
    :root{--HeaderHeight: 360px;--TabHeaderHeight: 140px;}
    .navbar{padding: 30px 0px 15px 0px;}
    .admin-breadcrumbs{padding-top: 30px;}
    .nav-right{gap: 4px;}
    .form-container{padding: 42px 20px;}
}
@media(max-width: 420px){
    .nav-left button{min-width: 100px;}
    .nav-right{gap: 8px;}
    .tabheader{padding: 0 15px;}
    .container{padding-left: 10px;padding-right: 10px;}
    .form-container{padding: 40px 15px;}
}